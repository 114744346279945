/* Style Custom for @material/react-tab */
.mdc-tab-scroller__scroll-content {
    width: 100%;
}
.mdc-tab .mdc-tab--active {
    background-color: white !important;
}

.mdc-tab__text-label {
    color: black !important;
}

.mdc-tab__ripple::before {
    background-color: white !important;
}
.mdc-tab__ripple::after {
    background-color: white !important;
}
input[type="submit"]:hover, 
input[type="reset"]:hover, 
input[type="button"]:hover, 
button:hover, .button:hover {
    background-color: #8b8b8b !important;
}

.mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: red !important;
}
/* ------------------------------------ */